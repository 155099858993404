<template>
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-2">
            <b-form-input
              size="sm"
              aria-label="Surname"
              :placeholder="$t('PROFILE.SURNAME')"
              v-model="filterSurname"
              trim
            ></b-form-input>
            <b-form-input
              size="sm"
              aria-label="Name"
              :placeholder="$t('COMMON.NAME')"
              v-model="filterName"
              trim
            ></b-form-input>
            <vue-typeahead-bootstrap
              id="filter1"
              v-model="filterEmail"
              :minMatchingChars="1"
              trim
              size="sm"
              :placeholder="$t('PROFILE.EMAIL')"
              @input="
                requestSuggestionVariants(
                  'identifierSuggestionProcessor',
                  filterEmail
                )
              "
              :data="suggestedVariants"
              ref="identifierTypeahead"
              :serializer="(s) => s.name"
            >
            </vue-typeahead-bootstrap>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Clear"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        >
        </b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.formattedUserName,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import Common from "../../common";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";

export default {
  name: "users",
  mixins: [SuggestionProcessorMixin],
  components: {},
  data() {
    return {
      fields: [
        { key: "id", formatter: Common.idObjectFormatter, sortingKey: "el.id" },
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CREATED"),
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "changed",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CHANGED"),
          sortingKey: "el.changed",
          sortable: true,
        },
        { key: "formattedUserName", label: this.$t("ORDERS.USER_NAME") },
      ],

      filterName: "",
      filterSurname: "",
      filterEmail: "",

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/platform/api/user",
        requestType: "GET",
        requestParams: {},
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,

      errors: [],
      calculate: false,
      selectedItem: null,

      filterUser: "",
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.USERS") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "user" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({ name: "user", params: { id: this.selectedItem.id } });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/platform/api/user/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["fields.surname"] =
        this.filterSurname.length > 0 ? this.filterSurname : null;
      this.getList.requestParams["fields.name"] =
        this.filterName.length > 0 ? this.filterName : null;
      this.getList.requestParams["identifierValue"] =
        this.filterEmail.length > 0 ? this.filterEmail : null;
      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["fields.surname"];
      delete this.getList.requestParams["fields.name"];
      delete this.getList.requestParams["identifierValue"];

      this.filterName = "";
      this.filterSurname = "";
      this.filterEmail = "";
      this.$refs.identifierTypeahead.$data.inputValue = "";

      this.loadList();
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },
  },
};
</script>
